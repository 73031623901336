.overlay-navigation {
	height: 0;
	overflow: visible;
	position: fixed;
	left:  25/16rem;
	right: 25/16rem;
	top: 50%;

	a {
		background-color: fade( @white, 30 );
		cursor: pointer;
		display: block;
		height: 40/16rem;
		position: absolute;
		top: 0;
		width: 35/16rem;

		&:before,
		&:after {
			background-color: @white;
			content: '';
			height: 4/16rem;
			left: 50%;
			position: absolute;
			top: 50%;
			width: 17/16rem;
		}
		&:before {
			transform-origin: left bottom;
			transform: translate3d( -40%, -50%, 0 ) rotate( -45deg );
		}
		&:after {
			transform-origin: left top;
			transform: translate3d( -40%, -50%, 0 ) rotate( 45deg );
		}

		&:first-of-type {
			left: 0;
			transform: rotate( 0deg ) translate3d( 0, -50%, 0 );
		}
		&:last-of-type {
			right: 0;
			transform: rotate( 180deg ) translate3d( 0, -50%, 0 );
			transform-origin: top center;
		}
	}

	.media-max( @desktop - 1, {
		left: 0;
		right: 0;

		a {
			height: 50/16rem;
			width: 40/16rem;

			&:first-of-type {
				left: 0;
			}
			&:last-of-type {
				right: 0;
			}
		}
	} );
}
