.container-footer {
	color: #666;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;

	ul {
		font-size: 0;
		list-style: none;
		margin: 0 0 20/16rem;
		padding: 0;
		display: flex;
		justify-content: center;

		li {
			display: block;
			font-size: 12/16rem;
			letter-spacing: initial;
			margin-right: 9/16rem;
			padding-right: 10/16rem;
			position: relative;
			white-space: nowrap;

			&:after {
				background-color: #666;
				content: '';
				display: block;
				height: 1em;
				position: absolute;
				right: 0;
				top: 0;
				width: 1/16rem;
			}
			&:last-of-type {
				margin-right: 0;
				padding-right: 0;

				&:after {
					display: none;
				}
			}

			&:nth-of-type( 2 ),
			&:nth-of-type( 3 ),
			&:nth-of-type( 4 ),
			&:nth-of-type( 5 ),
			&:nth-of-type( 6 ) { display: none; }

		}
		.media-min( @mobile-plus, {
			li:nth-of-type( 2 ) { display: block; }
		} );
		.media-min( @tablet, {
			li:nth-of-type( 5 ) { display: block; }
		} );
		.media-min( @desktop, {
			li:nth-of-type( 2 ),
			li:nth-of-type( 3 ),
			li:nth-of-type( 4 ),
			li:nth-of-type( 6 ) { display: block; }
		} );
	}
}

.showroom {
	.container-footer {
		ul li {
			color: @white;
			&:after {
				background-color: @white;
			}
		}
	}
}