.media-max( @tablet - 1, {
	body {
		-webkit-tap-highlight-color: fade( @black, 10 );
	}
	.container-header {
		@t: 4/16rem;
		@d: .25s;

		header {
			nav.main {
				background-color: fade( @black, 0 );
				content: '';
				display: block;
				height: 0;
				left: 0;
				top: 0;
				transition: background-color @d 0.75*@d, height 0s 1.75*@d;
				position: fixed;
				width: 100%;

				ul {
					background-color: @white;
					bottom: 0;
					height: auto;
					left: 0;
					overflow: auto;
					padding-bottom: 90/16rem;
					position: fixed;
					right: 0;
					top: 0;
					transform: translate3d( 100%, 0, 0 );
					transition: transform @d;
					z-index: 2;
				}

				.menu-toggle {
					display: block;
					height: 32/16rem;
					position: fixed;
					right: 25/16rem;
					top: 18/16rem;
					width: 40/16rem;

					> span {
						background-color: @black;
						display: block;
						height: @t;
						margin: 14/16rem auto 0;
						position: static;
						transition: width 0.75*@d, background-color 0.75*@d;
						transition-delay: 0.25*@d;
						width: 40/16rem;

						&:before,
						&:after {
							background-color: @black;
							content: '';
							display: block;
							height: @t;
							left: 0;
							position: absolute;
							transition: transform @d, background-color 0.75*@d;
							transition-delay: 0;
							width: 40/16rem;
						}
						&:before {
							transform: translate3d( 0, -10/16rem, 0 );
						}
						&:after {
							transform: translate3d( 0, 10/16rem, 0 );
						}
					}
				}
				.menu-logo {
					background-size: 140/16rem;
					position: static;
					margin: 90/16rem auto 72/16rem;
					width: 140/16rem;
				}
			}

			.open-filter {
				background-color: #ededed;
				display: block;
				font-size: 20/16rem;
				padding: 8.5/16rem 20.25/16rem;
				position: absolute;
				left: 0;
				text-decoration: none;
				top: 13.5/16rem;
			}

			nav.main {
				> ul {
					li {
						display: block;
						float: none;
						line-height: 50/16rem;
						margin: 0 auto;
						text-align: center;
						width: auto;

						a {
							color: @black;
							font-size: 24/16rem;
							transition: color 0s .5s;
						}
					}
				}
			}
		}

		&._menu-on {
			header {
				nav.main {
					height: 100%;
					transition: height 0s;
					width: 100%;

					ul {
						transform: translate3d( 0, 0, 0 );
					}
					> ul li a {
						transition: color 0s;
					}

					.menu-toggle > span {
						background-color: fade( @black, 0 );
						transition-delay: 0;
						transition-duration: @d, 0s;
						width: 0;

						&:before {
							transform:
								translate3d( 0, 0, 0 )
								rotate3d( 0, 0, 1, -45deg );
							transform-origin: center;
							transform-delay: @d;
						}
						&:after {
							transform:
								translate3d( 0, 0, 0 )
								rotate3d( 0, 0, 1, 45deg );
							transform-origin: center;
							transform-delay: @d;
						}
					}
				}
			}
		}
	}

	html.__blur {
		.container-main,
		.container-footer {
			filter: blur( 5/16rem );
			transition: filter .35s;
		}
		.container-header {
			&:before {
				background-color: fade( @black, 50 );
				bottom: 0;
				content: '';
				display: block;
				left: 0;
				position: fixed;
				right: 0;
				top: 0;
				z-index: 2;
			}

			h1,
			.open-filter {
				filter: blur( 5/16rem );
				transition: filter .35s;
			}
		}
	}

	body:not( .references ) {
		.open-filter {
			display: none !important;
		}
	}
} );