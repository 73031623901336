/**
 * media-query helper
 */
.media-max( @width, @rules ) {
	@media only screen and ( max-width: @width ) {
		@rules();
	}
}
.media-min( @width, @rules ) {
	@media only screen and ( min-width: @width ) {
		@rules();
	}
}
.media-min-max( @min, @max, @rules ) {
	@media only screen and ( max-width: @max ) and ( min-width: @min ) {
		@rules();
	}
}



.mobile( @rules ) {
	.media-max( @mobile-plus - 1, @rules );
}
.mobile-plus( @rules ) {
	.media-min-max( @mobile-plus, @tablet - 1, @rules );
}
.tablet( @rules ) {
	.media-min-max( @tablet, @desktop - 1, @rules );
}
.desktop( @rules ) {
	.media-min( @desktop, @rules );
}
