.the-content {
	display: block;
	font-size: 14/16rem;
	height: auto;
	line-height: 1.6;
	min-height: 100%;
	padding-bottom: 100/16rem;


	.media-max( @tablet - 1, {
		font-size: 20/16rem;
		display: block;
	} );

	> *,
	.col > * {
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}

	h1 {
		display: none;
	}
	h2 {
		font-size: 22/16rem;
		margin: 1.6em 0 0.8em;

		&:first-of-type {
			margin-top: 0;
		}
	}
	h3 {
		font-size: 18/16rem;
		font-weight: bold;
		line-height: 1.6;
		margin-bottom: 0;

		& + p,
		& + ul {
			margin-top: 0;
		}

		small, a {
			font-weight: normal;
			font-size: 14/16rem;
		}
	}
	h4 {
		font-size: 16/16rem;
		font-weight: bold;
		margin-bottom: 0;
	}
	h5 {

	}
	h6 {

	}
	address {
		font-style: normal;
	}

	ul {
		list-style-type: circle;
		padding-left: 20/16rem;
	}
	li {
		margin: .5rem 0;
	}

	p {
		display: block;
		margin: 1em 0;
	}
	span {

	}
	b {
		font-weight: bold;
	}
	i {
		font-style: italic;
	}
	a {
		&.mobile-only,
		&.centered-circle {
			position: relative;
			overflow: hidden;

			&:before {
				content: '';
				display: block;
				padding-top: 100%;
				width: 100%;
			}

			&:not( .centered-circle ):before {
				padding-top: 120%;
			}
		}
		&.mobile-only {
			display: none;
			height: auto;
			margin: 20/16rem -20/16rem;
			width: auto;

			.media-max( @tablet - 1, {
				display: block;
			} );

			> img {
				position: absolute;
				top: 50%;
				transform: translate( 0, -50% );
			}
		}
		&.centered-circle {
			border-radius: 50%;
			margin: 20/16rem auto;
			width: 50%;

			&.thomasschmied {
				> img {
					height: auto;
					max-width: initial;
					transform: translate(-8.5%, -16%) scale(2.2);
					width: 100%;
				}
			}
			&.romyschmied {
				margin-top: 2em;
				> img {
					height: auto;
					width: 100%;
					transform: translate(27.5%, -16%) scale(2.2);
				}
			}
		}
	}
	small {
		display: block;
		font-size: 0.8em;
		line-height: 1.6em;
	}

	img {
		max-width: 100%;
		height: auto;

		&.mobile-only {
			display: block;
			margin: 20/16rem -20/16rem;
			width: 100%;
			max-width: initial;
			width: auto;
		}
	}

	.row {
		display: flex;
		flex-direction: row;
		padding: 0 20/16rem;
		margin: 0 -20/16rem;

		.media-max( @tablet - 1, {
			flex-wrap: wrap;
		} );
	}

	.col {
		flex-direction: column;
		flex-shrink: 1;
		flex-basis: 100%;
		padding-right: 40/16rem;
		min-width: 280/16rem;
		max-width: 100%;
		width: 100%;

		&:last-child {
			padding-right: 0;
		}

		&.images {
			flex-shrink: 2;
		}
	}

	aside.images {
		ul {
			display: flex;
			justify-content: center;
		}
		ul, li {
			list-style: none;
			margin: 0;
			padding: 0;
		}
		li {
			display: inline-block;
			margin: 20/16rem 10/16rem;

			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
		}
		img {
			display: block;
			height: auto;
		}
	}

	.media-min( @tablet, {
		img.mobile-only {
			display: none;
		}
	} );
	.media-max( @tablet - 1, {
		.col {
			padding: 0 !important;

			& + .col {
				margin-top: 40/16rem;
			}

			h2, h3 {
				font-weight: bold;

			}
			h2 {
				font-size: 30/16rem;
				text-align: center;
			}
			h3 {
				font-size: 24/16rem;
				margin-bottom: 0;

				&.centered {
					text-align: center;
				}

				& + p,
				& + ul {
					margin-top: 0;
				}

				small {
					font-weight: normal;
					font-size: 20/16rem;
				}
			}
			p {
				margin-bottom: 30/16rem;
			}
			ul.centered,
			.centered + ul {
				list-style: none;
				padding-left: 0;

				> li {
					margin: 20/16rem 0;
					text-align: center;
				}
			}
		}
		aside.images {
			display: none;
		}
	} );
}


.media-min( @tablet, {
	.the-content {
		flex-direction: row;
		.col {
			width: 70%;
		}
		aside.images {
			ul { display: block; }
			li { display: block; margin: 0 0 20/16rem; }
			img { max-height: initial; }
		}
	}
} );

.imprint {
	.the-content {
		display: block;
		overflow-x: hidden;

		> div {
			display: flex;
			width: 100%;
		}
		.col {
			&:nth-of-type( 1 ) {
				padding-right: 20/16rem;
			}
			&:nth-of-type( 2 ) {
				padding-left: 20/16rem;
			}
		}
	}
}
.error-404 {
	h2 {
		font-size: 60/16rem;
		font-weight: normal;
		line-height: 1.6;
		margin-bottom: 40/16rem;
		text-align: center;
	}
	p {
		text-align: center;
	}
}