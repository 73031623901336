.container {
	&-showroom {
		background-color: #f0f0f0;
		position: fixed;
		transform: translate3d( 0, 0, 0 );
		z-index: 0;

		&, > div {
			bottom: 0;
			left: 0;
			right: 0;
			top: 0;
		}

		> div {
			background-color: transparent;
			background-size: cover;
			background-position: center center;
			opacity: 1;
			position: absolute;
			transition: ~'opacity .5s';
			z-index: 0;

			&:first-of-type {
				z-index: 1;
			}
		}
	}
}
.showroom-logo {
	display: none;
	filter: drop-shadow( 0 1/16rem 1/16rem fade( @black, 40 ) );
	height: auto;
	left: 50%;
	max-height: 50% !important;
	max-width: 50% !important;
	position: fixed;
	top: 50%;
	transform: translate3d( -50%, -50%, 0 );
	transition: opacity 2s;
	width: auto;
	z-index: 1;

	.media-max( @tablet - 1, {
		display: block;
	} );
}

body.showroom {
	header h1 {
		display: none;
	}
	.container-header:not( ._menu-on ) {
		header nav {
			ul > li > a {
				color: @white;
				text-shadow: 0 1/16rem 1/16rem fade( @black, 70 );

				&.active {
					border-color: @white;

					&:after {
						background-color: @white;
						box-shadow: 0 1/16rem 0 0 fade( @black, 30 );
					}
				}
			}
			.menu-logo {
				background-image: url( '../imgs/thomas-schmied-logo-white.svg' );
				filter: drop-shadow( 0 1/16rem 1/16rem fade( @black, 40 ) );
			}
			header nav ul li a {
				&:hover,
				.active {
					border-color: @white;
				}
			}
		}
	}
	.media-max( @tablet - 1, {
		.container-header:not( ._menu-on ) {
			header .menu-toggle > span {
				&, &:before, &:after {
					background-color: @white;
					box-shadow: 0 1/16rem 2/16rem -1/16rem fade( @black, 40 );
				}
			}

			&._menu-on {
				header {
					.menu-toggle > span {
						&, &:before, &:after {
							background-color: @black;
						}
					}
				}
			}
		}
	} );


	.container-footer {
		position: fixed;
		color: @white;
		text-shadow: 0 1/16rem 1/16rem fade( @black, 70 );

		ul li:after {
			background-color: @white;
			box-shadow: 0 1/16rem 2/16rem -1/16rem fade( @black, 40 );
		}
	}

	.overlay-navigation,
	header nav ul > li:not( :first-of-type ),
	.container-footer {
		opacity: 1;
		transition: ~'opacity .35s';
	}


}

html.__fade {
	.overlay-navigation,
	header nav ul > li:not( :first-of-type ),
	.container-footer {
		opacity: .3;
		transition: ~'opacity 1s';
	}

	.media-max( @tablet - 1, {
		header nav ul > li:not( :first-of-type ) {
			opacity: 1;
		}
	} );
}