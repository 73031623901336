body.references {
	.the-content {
		height: auto;
		overflow: hidden;

		.media-max( @tablet - 1, {
			.row {
				display: block;
			}
		} );

		.col {
			&:nth-of-type( 1 ) {
				flex-basis: 630/16rem;
				flex-grow: 0;
				flex-shrink: 1;
				padding: 0;
			}
			&:nth-of-type( 2 ) {
				flex-basis: 300/16rem;
				flex-grow: 1;
				flex-shrink: 1;
				padding-left: 3.125%;
			}
		}
	}

	h2 {
		font-size: 14/16rem;
		font-weight: bold;
		margin: 0 0 1em;
	}

	.the-content ul {
		list-style: none;
		margin: 0;
		padding: 0;
		width: 100%;

		&.references {
			li {
				box-sizing: border-box;
				display: block;
				float: left;
				font-size: 18/16rem;
				max-width: 50%;
				padding-right: 38/16rem;
				position: relative;
				transform: translate3d( 0, 0, 0 );
				width: 50%;

				&:before {
					content: '';
					display: block;
					height: 0;
					width: 100%;
					padding-top: 88.08664%;
				}
				&:hover {
					> .name {
						opacity: 1;
					}
				}

				&.ref-enter-active {
					transition: opacity .35s, transform .5s;
				}
				&.ref-leave-active {
					transition: opacity .100s;
					transition-delay: 0 !important;
				}
				&.ref-enter, &.ref-leave-to {
					opacity: 0;
				}
				&.ref-enter-to, &.ref-leave {
					opacity: 1;
				}
				&.ref {
					&-enter {
						transform: translate3d( 0, 20%, 0 );
						&-to {
							transform: translate3d( 0, 0, 0 );
						}
					}
				}

				> div {
					background-position: center center;
					background-size: cover;
					cursor: pointer;
					left: 0;
					line-height: 0;
					position: absolute;
					top: 0;
					width: 90.47%;
					z-index: 0;

					&:before {
						content: '';
						display: block;
						padding-top: 75.0877%;
						height: 0;
						width: 100%;
					}
				}
				.name {
					background-color: fade( @white, 85 );
					bottom: 12%;
					left: -1/16rem;
					line-height: 1.65em;
					opacity: 0;
					padding: 20/16rem;
					position: absolute;
					right: 9.5%;
					text-decoration: none;
					top: -1/16rem;
					transition: opacity .35s;
				}
				.media-max( @desktop - 1, {
					margin-bottom: 38/16rem;
					padding-left: 8/16rem;

					&:before {
						padding-top: 74.5%;
					}

					.name {
						background-color: #ededed;
						box-shadow: 0 0 0 8/16rem #ededed;
						display: inline;
						opacity: 1;
						padding: 0;
						position: relative;
					}
				} );
			}
		}

		&.more-references,
		&.reference-categories {
			line-height: 1;
			margin-bottom: 45/16rem;

			li {
				margin: 0 0 14/16rem 0;
			}
		}

	}
}
ul.reference-categories {
	a {
		text-decoration: none;

		&._on {
			text-decoration: underline;
		}
	}
}

// mobile
.media-max( @tablet - 1, {
	body.references .the-content {
		h2 {
			font-size: 30/16rem;
			margin: 20/16rem 0;
			text-align: center;
		}
		ul.references {
			width: 100%;

			li {
				clear: both;
				max-width: 100%;
				padding-right: 8/16rem;
				width: 100%;

				> div {
					width: 100%;
				}
				.name {
					font-size: 20/16rem;
				}
			}
		}
		ul.more-references {
			box-sizing: border-box;
			padding-left: 20/16rem;

			> li {
				list-style: circle;
				line-height: 1.4;
				padding-left: 5/16rem;
			}
		}
		.filter-container {
			display: none;
		}

		div.row div.col:nth-of-type( 2 ) div:nth-of-type( 2 ) {
			display: none;
		}
	}
} );

