.reference-detail {
	header {
		h1 {
			margin-bottom: 30/16rem;
		}
	}
	.media-min( @tablet, {
		header {
			height: auto;
			padding-top: 181/16rem;

			h1 {
				font-size: 18/16rem;
				font-weight: normal;
				height: auto;
				margin: 20/16rem 0 14/16rem;
				overflow: auto;
			}
		}
	} );

	.the-content {
		.article {
			box-sizing: border-box;
			flex-basis: 660/16rem;

			figure {
				display: block;
				margin: 0;
				width: 100%;

				.media-max( @tablet - 1, {
					margin: 0 -20/16rem 20/16rem;
					width: auto;
				} );

				img {
					display: block;
					height: auto;
					width: 100%;
				}
			}

			.media-min( @tablet, {
				.gallery {
					display: none;
				}
			} );
		}
		aside {
			flex-basis: 300/16rem;

		}
		ul.gallery {
			display: flex;
			flex-direction: columns;
			flex-wrap: wrap;
			justify-content: flex-start;
			list-style: none;
			margin: 5/16rem 0 0 0;
			padding: 0;

			li {
				width: 33.33333%;
				position: relative;

				&:before {
					content: '';
					display: block;
					padding-top: 100%;
				}
				a {
					bottom: 10/16rem;
					display: block;
					left: 0;
					position: absolute;
					right: 10/16rem;
					top: 0;
				}
				img {
					display: block;
					position: static;
					transition: filter .35s;

					.media-min( @desktop, {
						filter: grayscale( 100% );
						&:hover {
							filter: grayscale( 0% );
						}
					} );
				}
			}
		}
	}
}
.media-max( @tablet - 1, {
	.reference-detail {
		.the-content {
			ul.gallery {
				justify-content: center;
				margin: 0 0 20/16rem;

				li {
					width: 14.3283582%;

					&:nth-of-type( n+7 ) {
						display: none;
					}
					& + li {
						margin-left: 2.6865671%;
					}

					a {
						right: 0;
						bottom: 0;
					}
					img {
						filter: none;
					}
				}
			}
		}
		aside {
			.gallery-container {
				display: none;
			}
		}
	}
} );