div.lightbox-container {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	z-index: 11;

	&:after {
		background-color: rgba( 0, 0, 0, .75 );
		content: '';
		bottom: 0;
		left: 0;
		opacity: 0;
		position: absolute;
		right: 0;
		top: 0;
		transition: opacity .35s;
		z-index: 0;
	}
	&._active:after {
		opacity: 1;
	}

	> .image {
		bottom: 100/16rem;
		left: 100/16rem;
		position: absolute;
		right: 100/16rem;
		top: 100/16rem;
		transition: opacity .35s, transform .25s;
		z-index: 1;

		.media-max( @tablet - 1, {
			@space: 40/16rem;
			bottom: @space;
			left: 0;
			right: 0;
			top: @space;
		} );

		figure, img {
			bottom: 0;
			left: 0;
			right: 0;
			top: 0;
			position: absolute;
		}

		figure {
			margin: 0;
			position: absolute;

			height: 100%;
			width: 100%;

			max-height: 100%;
			max-width: 100%;

			img, figcaption {
				margin: 0 auto;
			}

			img {
				display: block;
				height: auto;
				width: auto;
				top: 50%;
				transform: translateY( -50% );
				z-index: 1;
			}
			figcaption {
				background-color: rgba( 255, 255, 255, .75 );
				box-sizing: border-box;
				left: 50%;
				margin-top: -1/16rem;
				opacity: 0;
				padding: .75em;
				position: absolute;
				transform: translate3d( -50%, 0, 0 );
				z-index: 0;

				&._active {
					opacity: 1;
				}
			}
		}

		&._loading,
		&._fadeOut {
			opacity: 0;
		}
	}
}
.overlay-navigation {
	z-index: 12;
}