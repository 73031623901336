header {
	height: 181/16rem;
	overflow: visible !important;
	position: relative;
	z-index: 10;

	.media-max( @tablet - 1, {
		height: auto;
	} );

	h1 {
		display: block;
		height: 0;
		margin: 0;
		overflow: hidden;
		position: relative;
		width: 100%;
		z-index: -1;

		.media-max( @tablet - 1, {
			height: auto;
			margin: 75/16rem auto 40/16rem;
			font-size: 30/16rem;
			font-weight: bold;
			text-align: center;
		} )
	}


	nav.main {
		left: 20/16rem;
		position: absolute;
		right: 20/16rem;
		top: 0;

		.menu-toggle {
			display: none;
			z-index: 3;
		}
		.menu-logo {
			background-image: url( '../imgs/thomas-schmied-logo.svg' );
			background-position: top left;
			background-repeat: no-repeat;

			background-size: 100% auto;
			border-width: 0;
			display: block;
			height: auto;
			position: absolute;
			right: 0;
			text-decoration: none;
			top: 30/16rem;
			width: 110px;

			img {
				border: 0;
				display: block;
				height: auto;
				visibility: hidden;
				width: 100%;
			}
		}

		> ul {
			-webkit-overflow-scrolling: touch;
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				float: left;
				margin: 95/16rem 45/16rem 0 0;

				&:first-of-type {
					margin: 0;
					width: 0;
				}

				a {
					color: @grey;
					font-size: 18/16rem;
					text-decoration: none;
					transition: color .15s;

					&:hover {
						color: @black;
					}

					&.active {
						position: relative;
						color: @black;

						&:after {
							background-color: @black;
							bottom: -2/16rem;
							content: '';
							height: 1/16rem;
							left: 1/16rem;
							position: absolute;
							width: 100%;
						}
					}
				}
			}
		}
	}

	nav.subnavigation {
		background-color: #ededed;
		bottom: 0;
		box-sizing: border-box;
		position: fixed;
		top: 0;
		left: 0;
		min-width: 270/16rem;
		max-width: 315/16rem;
		overflow-y: auto;
		padding: 20/16rem;
		right: 60/16rem;
		transition: transform .35s;
		transform: translate3d( -100%, 0, 0 );
		z-index: 11;

		.open-filter {
			background-color: #ededed;
			display: block;
			font-size: 20/16rem;
			padding: 8.5/16rem 20.25/16rem;
			position: absolute;
			right: 0;
			text-decoration: none;
			top: 13.5/16rem;
			transition: opacity 0s .35s;
			transform: translate3d( 100%, 0, 0 );
		}
		.close-filter {
			@t: 4/16rem;

			display: block;
			height: 32/16rem;
			position: absolute;
			right: 17/16rem;
			top: 18/16rem;
			width: 40/16rem;

			&:before,
			&:after {
				background-color: @black;
				content: '';
				display: block;
				height: 4/16rem;
				left: 0;
				margin-top: -2/16rem;
				top: 50%;
				position: absolute;
				width: 40/16rem;
			}

			&:before {
				transform:
					translate3d( 0, 0, 0 )
					rotate3d( 0, 0, 1, -45deg );
				transform-origin: center;
			}
			&:after {
				transform:
					translate3d( 0, 0, 0 )
					rotate3d( 0, 0, 1, 45deg );
				transform-origin: center;
			}
		}

		h2 {
			text-align: left;
			font-size: 30/16rem;
			margin-top: 70/16rem;
		}
		ul.reference-categories {
			list-style: none;
			margin: 0;
			padding: 0;
			width: 100%;

			li {
				font-size: 20/16rem;
				margin: 20/16rem 0;
			}
		}
	}


	.media-min( @tablet, {
		.subnavigation,
		.open-filter {
			display: none !important;
		}
	} );
}
.container-header._on-subnavigation {
	.menu-toggle {
		filter: blur( 5/16rem );
		transition: filter .35s;
	}
	nav.subnavigation {
		transform: translate3d( 0, 0, 0 );

		.open-filter {
			opacity: 0;
			display: none;
		}
	}
}


@import (less) './mobile-menu.less';