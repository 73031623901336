.container-map {
	bottom: 55/16rem;
	left: 0;
	position: fixed;
	right: 0;
	top: 181/16rem;
	z-index: 0;

	> .map {
		background-image: url( '/images/kontakt/anfahrt.png' );
		background-position: center;
		background-size: cover;

		height: 100%;
		width: 100%;

		&__credits {
			background-color: fade( #fff, 50 );
			bottom: 0;
			font-size: 10/16rem;
			padding: 0 8/16rem;
			position: absolute;
			right: 0;
			transition: background-color .35s;

			&:hover {
				background-color: #fff;
			}
		}
	}
}
.the-content .container-address {
	background-color: @white;
	float: left;
	margin-left: -20/16rem;
	padding: 14/16rem 20/16rem 0;
	position: relative;
	z-index: 1;

	h2 {
		font-size: 14/16rem;

		> span {
			font-size: 14/16rem;
			font-weight: normal;
		}
	}
}
.contact {
	.the-content {
		overflow-x: visible;
	}
	.wrapper-main {
		position: relative;
	}
}

.media-max( @tablet - 1, {
	.container-map {
		bottom: 0;
		top: 0;
		z-index: -1;

		&:before {
			background-color: fade( @white, 75 );
			bottom: 0;
			content: '';
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 1;
		}

		.map {
			&__credits {
				background-color: transparent !important;
				bottom: 10/16rem;
				box-sizing: border-box;
				left: 0;
				padding: 0 40/16rem;
				right: 0;
				text-align: center;
				transition: opacity .35s;
				width: 100%;
				z-index: 2;

				&--hidden {
					opacity: 0;
				}
			}
		}
	}
	.the-content .container-address {
		background-color: transparent;
		float: none;
		margin: 0 -20/16rem;
		padding: 0 20/16rem;
		position: static;
		text-align: center;

		h2 {
			font-size: 24/16rem;
			line-height: 32/16rem;

			span {
				font-size: 20/16rem;
			}
		}

		.maps-link {
			display: block;
			margin: 0/16rem auto 0;
			width: 200/16rem;

			> small {
				font-size: 1em;
			}
		}
	}
} );